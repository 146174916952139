<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" v-loading='loading' label-width="130px">
    <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>官方社群</span>
        </div>
        <div style='padding:0 10px;'>
          <div style='font-size: 14px;margin-left:5px;color: #909399;margin-bottom:10px;'>官方社群：当顾客没有锁粉店员，或者锁粉店员未管理社群时，顾客将加入默认群聊</div>
          
          <el-form-item label="官方社群配置ID："  prop="config">
            <el-input v-model="ruleForm.config" style='width:400px;' placeholder='请从企业微信后台复制配置到此处'></el-input>
          </el-form-item>

          <el-form-item label="官方社群群主："  prop="phone">
            <el-input v-model="ruleForm.phone" maxlength='11' style='width:358px;' placeholder='请输入员工手机号'></el-input>
          </el-form-item>

          <div style='width:100%;margin-bottom:100px;border-top:1px solid #ddd;'>

            <p style='font-size: 18px;font-weight: bold;'>如何获得配置ID?</p>

            <div  class='step-item'>
              <div class='set-img'  v-for='(item,key) in stepList' :key='key'>
                <span class='step-text'>{{item.description}}</span>
                <br>
                <img :src ='item.img'/>
              </div>
            </div>
          </div>

        </div>
    </el-card>
    <div class="footer">
			<el-button type='primary' style="width:240px;" @click="saveForm" v-loading='btnLoading'>保存</el-button>
		</div>
  </el-form>
</template>

<script>
import apiList from '@/api/other'
export default {
  data(){
    var checkConfig = (rule, value, callback) =>{
      if(this.ruleForm.phone!=''){
        if(!value){
          return callback(new Error('请输入正确的社群配置ID'));
        }else if(/[\u4E00-\u9FA5]/g.test(value)){
          return callback(new Error('请输入正确的社群配置ID'));
        }else{
          return callback()
        }
      }else{
        return callback()
      }
      
    };
    var checkPhone = (rule, value, callback) =>{
      const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
       if(this.ruleForm.config!=''){
        if(!value){
          return callback(new Error('请输入群主手机号'));
        }else if(!reg.test(value)){
          return callback(new Error('请输入正确的手机号'));
        }else{
          return callback()
        }
      }else{
        return callback()
      }
    };
    return {
      loading:false,
      btnLoading:false,
      ruleForm:{
        config:'',
        phone:''
      },
      rules:{
        config: [{
          trigger: 'blur',
          validator:checkConfig
        }],
        phone: [{
          trigger: 'blur',
          validator:checkPhone
        }],
      },
      stepList:[
        {
          description:'1.进入企业微信后台，登录企业微信',
          img:'http://cdn.dkycn.cn/melyshop/image/socity-set-1.png'
        },
        {
          description:'2.依次点击客户联系->配置->加入群聊[设置]',
          img:'http://cdn.dkycn.cn/melyshop/image/socity-set-2.png'
        },
        {
          description:'3.点击[新建加入群聊]',
          img:'http://cdn.dkycn.cn/melyshop/image/socity-set-3.png'
        },
        {
          description:'4.选择在小程序中加入群聊',
          img:'http://cdn.dkycn.cn/melyshop/image/socity-set-4.png'
        },
        {
          description:'5.编辑可加入群聊信息',
          img:'http://cdn.dkycn.cn/melyshop/image/socity-set-5.png'
        },
        {
          description:'6.保存成功后，复制配置ID至该页面',
          img:'http://cdn.dkycn.cn/melyshop/image/socity-set-6.png'
        },
      ]
    }
  },
  created(){
    this.getInfo()
  },
  watch:{
    "ruleForm.config": function toggle(){
      this.$refs['ruleForm'].validateField('phone')
    },
    "ruleForm.phone": function toggle(){
      this.$refs['ruleForm'].validateField('config')
    }
  },
  methods:{
    async getInfo(){
      try{
        this.loading = true
        let result = await apiList.communityGroupSettingInit()
        if(result.IsSuccess){
          this.ruleForm.config  = result.Result.CommunityGroupId||''
          this.ruleForm.phone =  result.Result.GroupManagerPhone||''
        }
      } catch(e){
        console.log('e',e)
        this.loading = false
      } finally{
        this.loading = false
      }
    },
    async saveForm(){
      this.$refs['ruleForm'].validate(async (valid) => {
        	if (valid) {
            try{
              this.btnLoading = true
              let result = await apiList.communityGroupSettingSave({
                CommunityGroupId:this.ruleForm.config,
                GroupManagerPhone:this.ruleForm.phone,
              })
              if(result.IsSuccess){
              this.$message({
                type:'success',
                message:'操作成功',
                showClose:true
              })
              this.getInfo()
              }
            } catch(e){
              console.log('e',e)
              this.btnLoading = false
            } finally{
              this.btnLoading = false
            }

          }else{
						this.$common.completeFormInformation('请完善活动信息','is-error')
						console.log('error submit!!');
						return false;
          }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.step-item{
  width:100%;
  .set-img{
    display:inline-block;
    margin-top:50px;
    width:50%;
    .step-text{
      margin-left:10%;
    }
    img{
      width:90%;
    }
  }
}

	.suppierDetail {
    background-color: #fff;
    .config{

    }
  }
  .footer {
		box-shadow: 13px 1px 6px #999;
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
		width: 100%;
		text-align: center;
		z-index: 999;
	}
</style>
